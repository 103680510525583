import React from "react"
import FontAwesomeIcon from "../components/FontAwesomeIcon"

const Help = () => {
  const definition = elems => {
    return (
      <dl style={{ paddingTop: "15px" }}>
        {elems.map((elem, index) => {
          return (
            <div key={index} style={{ paddingBottom: "15px" }}>
              <FontAwesomeIcon icon={elem.icon} />
              <dt>{elem.concept}</dt>
              <dd>{elem.description}</dd>
            </div>
          )
        })}
      </dl>
    )
  }
  return (
    <>
      <div>
        <h2>Help</h2>
        <h4>Key concepts</h4>

        {definition([
          {
            icon: "address-book",
            concept: "Commissioning Body",
            description:
              "A body that commissions your organisation to undertake one or more projects.",
          },
          {
            icon: "business-time",
            concept: "Project",
            description:
              "A set of activities which are to be recorded in GO Results. Clients must be enrolled in a project before they can be invited to attend a project session.",
          },
          {
            icon: "building",
            concept: "Location",
            description:
              "A room or other location in which a project session is held.",
          },
          {
            icon: "chalkboard-teacher",
            concept: "Practitioner",
            description: "A person who leads a project session.",
          },
          {
            icon: "address-card",
            concept: "Client",
            description:
              "A person who can be enrolled in a project. Their actions, contributions and performance are recorded in a sessional record.",
          },
          {
            icon: "drum",
            concept: "Session",
            description:
              "An activity, the results of which can be recorded in GO Results. A session is led by a practitioner, is held in a location and is attended by one or more clients who have been invited to attend the session. The results of the session are recorded in a sessional record.",
          },
          {
            icon: "clipboard-list",
            concept: "Sessional Record",
            description:
              "A client-specific record of their contribution and performance in a project session. Each client who attends a session will have their own sessional record. The aggregate results of the group attending a session can also be viewed by a project co-ordinator or administrator.",
          },
        ])}

        <h4>User Roles</h4>
        <p>Each GO Results user will have one of three roles.</p>
        {definition([
          {
            icon: "users-cog",
            concept: "Administrator",
            description:
              "Can create and administer all aspects of the system. Can view all data in the system. Can see the administrator dashboard and run reporting across all projects. There can be one administrator in an organisation.",
          },
          {
            icon: "user-tie",
            concept: "Project Co-ordinator",
            description:
              "Is assigned (by the administrator) to one or more projects. Can create sessions and assign practitioners to led sessions. Can see information related to a project that they have permission to administer. There can be multiple project co-ordinators in an organisation.",
          },
          {
            icon: "chalkboard-teacher",
            concept: "Practitioner",
            description:
              "Can complete sessional records for sessions that they led.  There can be multiple practitioners in an organisation. They can only see information related to the sessions that they have been asked to lead.",
          },
        ])}
      </div>
    </>
  )
}

export default Help
