import React from "react"
import Component from "../components/Help"

const DevTestBedPage = () => {
  return (
    <>
      <Component/>
    </>
  )
}

export default DevTestBedPage
